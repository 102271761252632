import React from "react";
import Footer from "./components/Footer";
import LogoSlogan from "./components/LogoSlogan";
// import AndroidLogo from "./img/android.png";
// import AppleLogo from "./img/applelogo.png";


function App() {
  
  return (
    <div className="flex flex-col bg-white justify-start min-h-screen w-screen p-0 m-0">
      <div className="flex flex-col grow justify-center">
        <div className="flex flex-col p-4 grow w-full justify-center place-items-center">
            <div className="w-full mb-8"><LogoSlogan /></div>   
            <div className="mb-8 p-4 lg:p-5 text-center">
              <div className="text-black text-4xl lg:text-5xl mb-3 font-bold">
                See life through different eyes
              </div>
              <div className="description-block text-gray-500 mx-auto">
                Explore different life perspectives and share yours on POV social
              </div>
            </div>
            <div className="justify-center mx-auto text-center">
              <a href="https://forms.gle/oipVADJWjK8UWRTx9" rel="nofollow" title="Early Access to POV social app" id="access-button" className="block text-black px-5 py-3 mx-auto rounded-full">
                   Early Access
              </a>
            </div>
            {/* <div className="my-5 text-white ">Download now</div>
            <div className="flex w-full justify-center mx-auto text-center">
                <div className="mx-5 lg:mx-6">
                  <a href="#" rel="nofollow" title="Download POV for iPhone (iOS)" id="ios-download-button" className="block">
                    <img src={AppleLogo} width={60} alt="Download POV for iPhone (iOS)" className="drop-shadow-md" />
                  </a>
                </div>
                <div className="mx-5 lg:mx-6">
                  <a href="#" rel="nofollow" title="POV for Android Early Access" id="androind-waitlist-button" className="block">
                    <img src={AndroidLogo} width={60} alt="POV for Android Early Access" className="drop-shadow-md" />
                  </a>
                </div>
            </div>  */}
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default App;